import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ConstantUrl from "../util/ConstantUrl";
import { Parser } from "html-to-react";

export default function HealthLibrabryDetails() {
  let { id } = useParams();
  console.log(id,"herb id")
  const URL = new ConstantUrl();
  const [herbs, setHerbs] = useState([]);
  const [herb, setHerb] = useState([]);

  async function fetchData() {
    await axios
      .post(URL.herbDetails, { herb_id: id })
      .then((response) => {
        const herbs = response.data["data"];
        console.warn(herbs);
        setHerbs(herbs);
      });

    await axios.post(URL.herb).then((response) => {
      const herbdata = response.data["data"];
      setHerb(herbdata);
    });
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <>
      {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
      <section id="breadcrumb">
        <div className="container-fluid">
          <div className="container">
            <div className="breadcrumb-bar">
              <div>
                <Link to="/home">Home</Link>
              </div>
              <div>
                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
              </div>
              <div>Herbs Details</div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>

      {/* {{-- =============================================
                REMEDY DETAILS AREA
    ============================================  --}} */}
      <section id="remedy-detail-area">
        <div className="section-gap">
          <div className="container-fluid">
            <div className="container">
              <div className="block">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="current-remedy">
                      <div className="remedy-img">
                        <img
                          src={herbs.image}
                          alt="..."
                          className="w-100 h-100"
                        />
                      </div>
                      <div className="remedy-head text-truncate">
                        {herbs.title}
                      </div>
                      <div className="remedy-details">
                        {Parser().parse(herbs.details)}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="recent-remedy">
                      <div className="head">
                        <span>Recent Herbs</span>
                      </div>
                      <div className="remedy-grid">
                        {herb.map((herbdata) => (
                          <Link to={`/remedy-details/${herbdata.id}`}>
                            <div className="remedy">
                              <div className="remedy-img">
                                <img
                                  src={herbdata.image}
                                  alt="..."
                                  className="w-100 h-100"
                                />
                              </div>
                              <div className="remedy-detail">
                                <div className="remedy-name">
                                  {herbdata.title}
                                </div>
                                <div className="remedy-desc">
                                  {Parser().parse(herbdata.details)}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
