import './App.css';
import Home from './pages/Home';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import NoPage from './pages/NoPage';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Remedies from './pages/Remedies';
import FindDoctor from './pages/FindDoctor';
import ProductDetails from './pages/ProductDetails';
import ListProfile from './pages/ListProfile';
import RemedyDetails from './pages/RemedyDetails';
import DoctorProfile from './pages/DoctorProfile';
import ProductList from './pages/ProductList';
import Login from './pages/Login';
import Register from './pages/Register';
import UserProfile from './pages/UserProfile';
import Cart from './pages/Cart';
import CheckOut from './pages/CheckOut';
import ThankOrder from './pages/ThankOrder';
import ViewMore from './pages/ViewMore';
import Privacy from './pages/Privacy';
import Terms from './pages/terms';
import Forgetpassword from './pages/Forgetpassword';
import Changepassword from './pages/Changepassword';
import HealthLibrary from './pages/HealthLibrary';
import HealthLibrabryDetails from './pages/HealthLibrabryDetails';
import ResearchArticles from './pages/ResearchArticles';
import ResearchArticlesDetails from './pages/ResearchArticlesDetails';
import Herbs from './pages/Herbs';
import HerbsDetails from './pages/HerbsDetails';
import CaseStudies from './pages/CaseStudies';
import CaseStudiesDetails from "./pages/CaseStudiesDetails";

function App() {

  return (
    <BrowserRouter >
      <Routes>
        <Route exact path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='about' element={<AboutUs/>} />
          <Route path='contact' element={<ContactUs/>} />
          <Route path='remedies' element={<Remedies/>} />
          <Route path='find-doctors' element={<FindDoctor/>} />
          <Route path='product-details/:id' element={<ProductDetails/>} />
          <Route path='list-profile' element={<ListProfile/>} />
          <Route path='remedy-details/:id' element={<RemedyDetails/>} />
          <Route path='doctor-profile/:id' element={<DoctorProfile/>} />
          <Route path='product-list/:type/:id' element={<ProductList/>} />
          <Route path='login' element={<Login/>} />
          <Route path='forgetpassword' element={<Forgetpassword/>} />
          <Route path='changepassword' element={<Changepassword/>} />
          <Route path='register' element={<Register/>} />
          <Route path='user-profile' element={<UserProfile/>} />
          {/* <Route path='wishlist' element={<Wishlist/>} /> */}
          <Route path='cart' element={<Cart />} />
          <Route path='viewmore/:type' element={<ViewMore/>} />
          <Route path='checkout' element={<CheckOut/>} />
          <Route path='thankyou' element={<ThankOrder/>} />
          <Route path='health-library' element={<HealthLibrary/>} />
          <Route path='HealthLibrabryDetails/:id' element={<HealthLibrabryDetails/>} />
          <Route path='research-Articles' element={<ResearchArticles/>} />
          <Route path='researchArticlesDetails/:id' element={<ResearchArticlesDetails/>} />
          <Route path='herbsDetails/:id' element={<HerbsDetails/>} />
          <Route path='herbs' element={<Herbs/>} />
          <Route path='caseStudies' element={<CaseStudies/>} />
          <Route path='caseStudiesDetails/:id' element={<CaseStudiesDetails/>} />
          <Route path='privacy' element={<Privacy/>} />
          <Route path='terms' element={<Terms/>} />
          <Route path="/*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
