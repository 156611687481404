import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConstantUrl from "../util/ConstantUrl";

export default function Forgetpassword() {
  const URL = new ConstantUrl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [isError, setIserror] = useState(false);
  // const [errorshow, setErrorshow] = useState("");
  const [errorShow, setErrorShow] = useState("");
  const navigate = useNavigate();

  // Send OTP to user's email (static implementation)
  // const sendOtp = (e) => {
  //   e.preventDefault();
  //   const staticOtp = "123456"; // Define a static OTP
  //   setOtp(staticOtp); // Set the OTP directly
  //   setIsOtpSent(true); // Mark OTP as sent
  //   toast(`Static OTP is ${staticOtp}.`, { type: "info", theme: "dark" }); // Display OTP in toast for testing
  // };
  const handleRegister = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(URL.forgotPassword, {      
        email,      
      });

      if (!response.data.error) {
        setIsOtpSent(true);
        toast("OTP sent to your email.", { type: "info", theme: "dark" });
      } else {
        setErrorShow(response.data.data);
      }
    } catch (error) {
      setErrorShow("Otp send  failed. Please try again.");
    }
  }
  // const sendOtp = async (e) => {
  //   e.preventDefault();
  //   console.log(email,"email where otp send")
  //   try {
  //     const response = await axios.post(`${URL.forgotPassword}`, { email });
  //     if (response.data.success) {
  //       setIsOtpSent(true);
  //       toast("OTP sent to your email successfully.", {
  //         type: "success",
  //         theme: "dark",
  //       });
  //     } else {
  //       setIserror(true);
  //       setErrorshow(response.data.message || "Failed to send OTP.");
  //     }
  //   } catch (error) {
  //     setIserror(true);
  //     setErrorshow("Error sending OTP. Please try again.");
  //   }
  // };

  // Verify the OTP entered by the user
  // const verifyOtp = (e) => {
  //   e.preventDefault();
  //   if (otp === "123456") {
  //     setIsOtpVerified(true);
  //     toast("OTP verified successfully.", { type: "success", theme: "dark" });
  //   } else {
  //     setIserror(true);
  //     setErrorshow("Invalid OTP. Please try again.");
  //   }
  // };
  const verifyOtp = async (e) => {
    e.preventDefault();
    console.log(email,"email where otp is send ")
    console.log(otp,"otp of that email")
    try {
      const response = await axios.post(URL.resetPassword, { email, otp,password });
      if (!response.data.error) {
        setIsOtpVerified(true);
        toast("OTP verified successfully.", { type: "success", theme: "dark" });
        navigate("/login", { replace: true });
      } else {
        setIserror(true);
        setErrorShow(response.data.message || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      setIserror(true);
      setErrorShow("Error verifying OTP. Please try again.");
    }
  };

  // const handleVerifyOtp = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const response = await axios.post(URL.userOtpVerify, {
  //       email,
  //       otp,
  //     });

  //     if (!response.data.error) {
  //       setIsOtpVerified(true);
  //       toast("OTP verified successfully!", { type: "success", theme: "dark" });
  //       navigate("/login", { replace: true });
  //     } else {
  //       setErrorShow("Invalid OTP. Please try again.");
  //     }
  //   } catch (error) {
  //     setErrorShow("OTP verification failed. Please try again.");
  //   }
  // };


  // Submit the form after OTP verification
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      setIserror(true);
      setErrorShow("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(URL.forgetPassword, {
        email,
        password,
      });
      if (response.data.error) {
        setIserror(true);
        setErrorShow(response.data.message);
      } else {
        navigate("/login", { replace: true });
        toast("Password has been changed successfully.", {
          type: "success",
          theme: "dark",
        });
      }
    } catch (error) {
      setIserror(true);
      setErrorShow("Error resetting password. Please try again.");
    }
  };

  return (
    <>
      <section id="breadcrumb">
        <div className="container-fluid">
          <div className="container">
            <div className="breadcrumb-bar">
              <div>
                <Link to="/home">Home</Link>
              </div>
              <div>
                &nbsp; <i className="fa-solid fa-minus"></i> &nbsp;
              </div>
              <div>Forget Password</div>
            </div>
          </div>
        </div>
        <hr className="m-0" />
      </section>

      <section id="login-area">
        <div className="section-gap">
          <div className="container-fluid">
            <div className="container">
              <div className="login-block">
                <div className="row">
                  <div className="col-lg-6 my-auto">
                    <div className="login-content">
                      <div className="content-head">Welcome!</div>
                      <div className="content-slogan">Amogh Ayurved</div>
                      <div className="content-data">
                        Amogh Ayurved is an Online Store to buy 100% Natural
                        Herbal Tablets in India. The best remedy for healthy
                        life.
                      </div>
                      <div className="redirect">
                        Already have an account?{" "}
                        <Link to="/login">Login Here</Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="login-form">
                      <div className="form-head">Forget Password</div>
                      <div className="form">
                        {!isOtpSent ? (
                          <form onSubmit={handleRegister}>
                            <div className="group-input">
                              <label htmlFor="email">E-Mail</label>
                              <input
                                type="email"
                                name="email"
                                value={email}
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="..."
                                required
                              />
                            </div>
                            {isError && (
                              <p className="text-danger">{errorShow}</p>
                            )}
                            <div className="group-input">
                              <input
                                type="submit"
                                value="Send OTP"
                                className="submit"
                              />
                            </div>
                          </form>
                        ) : !isOtpVerified ? (
                          <form onSubmit={verifyOtp}>
                            <div className="group-input">
                              <label htmlFor="email">E-Mail</label>
                              <input
                                type="email"
                                name="email"
                                value={email}
                                id="email"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="..."
                                required
                              />
                            </div>
                            <div className="group-input">
                              <label htmlFor="otp">OTP</label>
                              <input
                                type="text"
                                name="otp"
                                value={otp}
                                id="otp"
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                required
                              />
                            </div>
                            <div className="group-input">
                              <label htmlFor="password">Password</label>
                              <input
                                type="text"
                                name="password"
                                value={password}
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter Password"
                                required
                              />
                            </div>
                            {isError && (
                              <p className="text-danger">{errorShow}</p>
                            )}
                            <div className="group-input">
                              <input
                                type="submit"
                                value="Reset Password"
                                className="submit"
                              />
                            </div>
                          </form>
                        ) : (
                          <form onSubmit={handleSubmit}>
                            <div className="group-input">
                              <label htmlFor="password">New Password</label>
                              <input
                                type="password"
                                name="password"
                                value={password}
                                id="password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="..."
                                required
                              />
                            </div>
                            <div className="group-input">
                              <label htmlFor="cpassword">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                name="cpassword"
                                value={cpassword}
                                id="cpassword"
                                placeholder="..."
                                onChange={(e) => setCpassword(e.target.value)}
                                required
                              />
                            </div>
                            {password !== cpassword && cpassword && (
                              <p className="text-danger">
                                Passwords do not match
                              </p>
                            )}
                            {isError && (
                              <p className="text-danger">{errorShow}</p>
                            )}
                            <div className="group-input">
                              <input
                                type="submit"
                                value="Submit"
                                className="submit"
                              />
                            </div>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
